






































const url = '/incidents/incidents.json';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';

@Component
export default class MyComponent extends Vue {
  public incidents = [] as any;
  public selectedIncident = 9999 as number;

  public mounted() {
    // @ts-ignore
    $.getJSON(url, (data: any) => {
      this.incidents = data.current_incidents.map((x: any) => {
        return {
          ...x,
          open: true,
        };
      });
      console.log(this.incidents);
    });
  }
}
