







































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import ModalComponent from "./ModalComponent.vue";
import ChartOptions from "../ChartData";
import CurrentIncidents from "./CurrentIncidents.vue";
import Incidents from "./Incidents.vue";

import VueApexCharts from "vue-apexcharts";

@Component
@Component({
  components: {
    apexchart: VueApexCharts,
    ModalComponent,
    Incidents,
    "current-indicents": CurrentIncidents,
  },
})
export default class CentixStatus extends Vue {
  get onlineOffline() {
    if (this.data == null) {
      return [];
    }

    const chartdata = this.data;
    return [
      {
        name: "Online",
        data: [chartdata.UpPercentage],
      },
      {
        name: "Offline",
        data: [chartdata.DownPercentage],
      },
    ];
  }

  setChartDescription() {
    var myDescription = "";

    //Contact met backend?
    if (this.data == null) {
      myDescription = "Fout bij ophalen gegevens";
    }

    //100% hosts online
    if (this.data.UpPercentage == 100) {
      myDescription = "All systems operational";
    }

    //More than half of hosts online
    if (this.data.UpPercentage < 100 && this.data.UpPercentage > 50) {
      myDescription = "Most systems operational";
    }

    //Less than half hosts online
    if (this.data.UpPercentage < 50) {
      myDescription = "Most systems not operational";
    } 
    
    //No hosts online
    if (this.data.UpPercentage == 0) {
      myDescription = "No systems operational";
    }

    this.chartDescription = myDescription;
  }

  public renderComponent = false;
  public showingModal = false;

  public data = [] as any;

  public chartOptions = ChartOptions;

  public isLoading = true;
  public fullPage = true;

  public chartDescription = "Loading";

  @Prop() private msg!: string;
  public async created() {
    //If statement die aangeeft of project in development wordt gerunt
    try {
      if (process.env.NODE_ENV === "development") {
        const result = await this.axios.get(
          "http://centixmonitor.nl/api/uptime"
        );
        this.data = result.data;
        console.log("in development");
      } else {
        const result = await this.axios.get("/api/uptime");
        this.data = result.data;
      }

      this.setChartDescription();

      setTimeout(() => {
        this.$set(this.chartOptions.subtitle, "text", this.chartDescription);
        this.renderComponent = true;
      }, 1000);
    } catch (error) {}
  }
}
