<template>
  <div class="row">
    <div class="col-12">
      <img class="centix-logo" src="http://localhost/centixmonitor/content/centix.png" />
      <hr>

      <div class="col-12 text-center">
          <p class="lead title">Thank you for registering.<br>
              You will be notified when problems in our data centre occur!

          </p>
          <img class="registering-succesful" src="@/assets/registered.svg" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/hide.css';

export default {};
</script>

<style scoped>
.registering-succesful{
    width: 70px;
}
.title{
    font-size: 1.3em;
}
</style>

