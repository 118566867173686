<template>
  <transition name="modal">
    <div class="modal-mask" @click.self="maskClick">
      <div class="modal-wrapper">
        <div class="modal-container" :style="getStyle()">
          <div v-if="!hideHeader" class="modal-header">
            <slot name="header"></slot>
            <button
              v-if="hasCloseButton"
              type="button"
              aria-label="Close"
              class="close"
              @click="$emit('close')"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <slot name="body"></slot>
          </div>

          <div v-if="!hideFooter" class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
// New default modal component (ModalViewBase is obsolete)
export default {
  props: {
    hasCloseButton: {
      type: Boolean,
      default: true,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    containerWidth: {
      type: String,
      default: '80vw',
    },
  },
  methods: {
    getStyle() {
      return {
        width: this.containerWidth,
      };
    },
    maskClick(event) {
      this.$log('mask click', event);
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0px auto;
  padding: 0.25em;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-body {
  margin: 0;
  padding: 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
