export default {
    colors: ['#28a745', '#6c757d'],
    chart: {
        stacked: true,
        stackType: '100%',
        toolbar: {
            show: false,
        },
    },
    plotOptions: {
        bar: {
            horizontal: true,
        },
    },
    xaxis: {
        floating: true,
        axisTicks: {
            show: false,
        },
        axisBorder: {
            show: false,
        },
        labels: {
            show: false,
        },
    },
    yaxis: {
        floating: true,
        axisTicks: {
            show: false,
        },
        axisBorder: {
            show: false,
        },
        labels: {
            show: false,
        },
    },
    legend: {
        itemMargin: {
            horizontal: 20,
            vertical: 15,
        },
        onItemClick: {
            toggleDataSeries: false
        },
    },
    subtitle: {
        // align: 'right',
        style: {
            fontSize: '16px',
            color: '#62656c',
        },
        text: ''
    },
    tooltip: {
        enabled: true,
        theme: "light",
        x:{
            show: false,
        }
    },
    onDatasetHover: {
        highlightDataSeries: false,
    },

};
