
















































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

@Component
export default class MyComponent extends Vue {
  public incidents = [] as any;
  public selectedIncident = 9999 as number;
  public url = null as any;

  public mounted() {

    if (process.env.NODE_ENV === "development") {
      this.url = "http://centixmonitor.nl/incidents/incidents.json";
    }
    else {
      this.url = "/incidents/incidents.json";
    }

    // @ts-ignore
    $.getJSON(this.url, (data: any) => {
      this.incidents = data.previous_incidents.map((x: any) => {
        return {
          ...x,
          open: false,
        };
      });
    });
  }

  public changeSelected(newVal: number) {
    if (newVal === this.selectedIncident) {
      this.selectedIncident = 9999;
    } else {
      this.selectedIncident = newVal;
    }
  }
}
